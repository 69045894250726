<template>
  <div class="category-item">
    <h2>{{ title }}</h2>
    <div class="row" :class="{'one-item': row.length==1, 'two-item': row.length==2, first: index===0}"  v-for="(row, index) in data" :key="index">
      <div class="item" v-for="(item, index) in row" :key="index" @click="$router.push(item.url)">
        <img :src="item.image" alt="">
        <article>
          <div class="name" v-html="item.name"></div>
          <div class="slogan" v-html="item.slogan"></div>
          <div class="price" v-if="item.price">
            <span class="currency">¥</span>
            {{ item.price }}
            <span v-if="item.priceStart" class="start">起</span>
          </div>
          <router-link :to="item.url" class="buy">{{ item.comingSoon ? '即将上市' : '查看详情' }}</router-link>
        </article>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'home-category-item',
  components: {
  },
  props: {
    title: {
      type: String,
      required: true
    },
    data: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
    }
  },
  created () {
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.category-item {
  position: relative;
  background: no-repeat top;
  background-size: 100% auto;

  h2 {
    line-height:15px;
    font-size:15px;
    border-left: 2px solid;
    padding-left: 10px;
    margin-top: 30px;
  }

  .row {
    display: flex;
    margin-top: 10px;

    .item {
      width: 100%;
      position: relative;
      overflow: hidden;
      border: 1px solid rgba(0, 0, 0, 0.07);
      border-radius:5px;
      background-color: #FFF;

      img {
        width: 100%;
        display: block;
      }

      .name {
        font-weight: bold;
      }

      .slogan {
        color: #666;
      }

      .buy {
        display: block;
        width: 75px;
        text-align: center;
        height: 22.5px;
        background-color: #D42D3B;
        border-radius: 15px;
        font-size: 10px;
        line-height: 22.5px;
        color: #FFF;
      }
    }

    &.one-item {
      article {
        position: relative;
        box-sizing: border-box;
        height: 75px;
        padding: 15px 20px 15px 15px;
      }

      .name {
        font-size: 12px;
        line-height: 100%;
        color: #333333;
      }

      .slogan {
        margin-top: 4.5px;
        font-size: 10px;
        line-height: 110%;
      }

      .price {
        position: absolute;
        top: 10px;
        left: 260px;
      }

      .buy {
        position: absolute;
        bottom: 16.5px;
        right: 20px;
      }
    }

    &.two-item {
      .item {
        width: 170px;
        text-align: center;

        &:first-child {
          margin-right: 10px;
        }

        article {
          padding-top: 9.5px;
          height: 97.5px;
          position: relative;
        }

        .name {
          font-size: 12px;
          line-height: 100%;
          width: 100%;
          color: #333333;
          word-break: keep-all;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          padding: 0 10px;
          box-sizing: border-box;
        }

        .slogan {
          margin-top: 2px;
          width: 100%;
          font-size:10px;
        }

        .price {
          margin-top: 9.5px;
        }

        .buy {
          display: inline-block;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          bottom: 10px;
        }
      }
    }
  }
}
</style>
