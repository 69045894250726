var render = function render(){var _vm=this,_c=_vm._self._c;return _c('footer',[_c('ul',[_c('li',[_c('router-link',{attrs:{"to":"/about"}},[_vm._v("关于我们")])],1),_vm._m(0),_vm._m(1),_vm._m(2),_c('li',[_c('router-link',{attrs:{"to":"/services"}},[_vm._v("服务")])],1),_c('li',{staticClass:"lan-choice",on:{"click":_vm.choiceLan}},[_c('a',[_vm._v("语言")])])]),(_vm.showLan)?_c('div',{staticClass:"mask",on:{"click":_vm.choiceLan}},[_vm._m(3)]):_vm._e(),_vm._m(4),_vm._m(5)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"https://dev.huami.com/"}},[_vm._v("开放平台")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"/news"}},[_vm._v("资讯")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"/services/store"}},[_vm._v("线下门店")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"langs"},[_c('a',{attrs:{"href":"https://www.amazfit.com/tw/"}},[_vm._v("繁體中文")]),_c('a',{attrs:{"href":"https://www.amazfit.com/kr/"}},[_vm._v("한국어")]),_c('a',{attrs:{"href":"https://www.amazfit.com/en/"}},[_vm._v("English")]),_c('a',{attrs:{"href":"https://www.amazfit.com/es/"}},[_vm._v("Español")]),_c('a',{attrs:{"href":"https://www.amazfit.com/ru/"}},[_vm._v("Русский")]),_c('a',{attrs:{"href":"https://www.amazfit.com/it/"}},[_vm._v("Italiano")]),_c('a',{attrs:{"href":"https://www.amazfit.com/de/"}},[_vm._v("Deutsch")]),_c('a',{attrs:{"href":"https://www.amazfit.com/fr/"}},[_vm._v("Français")]),_c('a',{attrs:{"href":"https://www.amazfit.com//jp/"}},[_vm._v("日本語")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"serivice"},[_vm._v(" 全国服务热线： "),_c('div',{staticClass:"phone"},[_vm._v("400-000-6666")]),_c('span',{staticClass:"tip"},[_vm._v("周一至周日 8:00-22:00")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"copyright"},[_vm._v(" 2019 Huami Co., Ltd. All rights reserved. "),_c('br'),_c('a',{attrs:{"href":"http://www.beian.miit.gov.cn","target":"_blank"}},[_vm._v("京ICP备15043545号-1")]),_vm._v(" 京ICP证160530号 "),_c('a',{attrs:{"target":"_blank","href":"http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802032324"}},[_c('img',{attrs:{"src":"https://site-cdn.huami.com/files/amazfit/mobile/assets/beian.png"}}),_vm._v("京公网安备 11010802032324号")])])
}]

export { render, staticRenderFns }