<template>
   <footer>
      <ul>
        <li>
          <router-link to="/about">关于我们</router-link>
        </li>
        <li>
          <a href="https://dev.huami.com/">开放平台</a>
        </li>
        <li>
          <a href="/news">资讯</a>
        </li>
        <li>
          <a href="/services/store">线下门店</a>
        </li>
        <li>
          <router-link to="/services">服务</router-link>
        </li>
        <li class="lan-choice" @click="choiceLan">
          <a>语言</a>
        </li>
      </ul>
      <div v-if="showLan" class="mask" @click="choiceLan">
        <div class="langs">
          <a href="https://www.amazfit.com/tw/">繁體中文</a>
          <a href="https://www.amazfit.com/kr/">한국어</a>
          <a href="https://www.amazfit.com/en/">English</a>
          <a href="https://www.amazfit.com/es/">Español</a>
          <a href="https://www.amazfit.com/ru/">Русский</a>
          <a href="https://www.amazfit.com/it/">Italiano</a>
          <a href="https://www.amazfit.com/de/">Deutsch</a>
          <a href="https://www.amazfit.com/fr/">Français</a>
          <a href="https://www.amazfit.com//jp/">日本語</a>
        </div>
      </div>
      <!-- <div class="about">
        关于我们：<img src="https://site-cdn.huami.com/files/amazfit/mobile/assets/weibo.png" alt=""> <img src="https://site-cdn.huami.com/files/amazfit/mobile/assets/wechat.png" alt="">
      </div> -->

      <div class="serivice">
        全国服务热线：
        <div class="phone">400-000-6666</div>
        <span class="tip">周一至周日 8:00-22:00</span>
      </div>
      <p class="copyright">
        2019 Huami Co., Ltd. All rights reserved. <br/>
        <a href="http://www.beian.miit.gov.cn" target="_blank">京ICP备15043545号-1</a> 京ICP证160530号 <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802032324"><img src="https://site-cdn.huami.com/files/amazfit/mobile/assets/beian.png" />京公网安备 11010802032324号</a>
      </p>
    </footer>
</template>

<script>
export default {
  name: 'footer_m',
  data () {
    return {
      showLan: false
    }
  },
  methods: {
    choiceLan () {
      this.showLan = !this.showLan
    }
  }
}
</script>

<style lang="scss" scoped>
footer {
    padding: 37.5px;
    background-color: #FFF;
    color: #333;

    ul{
      li {
        a {
          display: inline-block;
          width: 100%;
          font-size:14px;
          line-height: 52px;
          border-bottom: 0.5px solid #333;
          color: #333333;
        }
      }
    }

    .about {
      margin-top: 40px;
      img {
        margin-left: 20px;
        width: 22.5px;
      }
    }

    .serivice {
      margin-top: 38.5px;
      font-size:15px;

      .tip {
        font-size: 12px;
      }
    }

    .phone {
      margin-top: 9.5px;
      font-size:30px;
      font-weight:bold;
    }
    .mask {
      position: fixed;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      background: rgba(0,0,0,0.5);
       z-index: 99999;
    }
    .langs {
      position: fixed;
      width: 200px;
      right: 0;
      top: 0;
      height: 100%;
      background: #fff;
      padding:0 10px;
      z-index: 99999;
      a {
        display: block;
        line-height: 12vw;
        font-size: 4vw;
        text-align: center;
        color: #333;
        border-bottom: 1px solid #eee;
      }
    }
    .copyright {
      a  {
        color: #333;
      }
    }
  }
</style>
