import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routerPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return routerPush.call(this, location).catch((error) => error)
}

const routerReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace (location) {
  return routerReplace.call(this, location).catch((error) => error)
}

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/20200922',
    name: '20200922',
    component: () => import(/* webpackChunkName: "20200922" */ '../views/20200922.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/category',
    name: 'category',
    component: () => import(/* webpackChunkName: "category" */ '../views/Category.vue')
  },
  {
    path: '/products/amazfit-x',
    name: 'amazfit-x',
    component: () => import('../views/products/amazfit-x.vue')
  },
  {
    path: '/products/antelope',
    name: 'antelope',
    component: () => import('../views/products/antelope.vue')
  },
  {
    path: '/products/band2',
    name: 'band2',
    component: () => import('../views/products/band2.vue')
  },
  {
    path: '/products/bip',
    name: 'bip',
    component: () => import('../views/products/bip.vue')
  },
  {
    path: '/products/bip-lite',
    name: 'bip-lite',
    component: () => import('../views/products/bip-lite.vue')
  },
  {
    path: '/products/bip-colorful-wristbands',
    name: 'bip-colorful-wristbands',
    component: () => import('../views/products/bip-colorful-wristbands.vue')
  },
  {
    path: '/products/coat',
    name: 'coat',
    component: () => import('../views/products/coat.vue')
  },
  {
    path: '/products/equator',
    name: 'equator',
    component: () => import('../views/products/equator.vue')
  },
  {
    path: '/products/gtr',
    name: 'gtr',
    component: () => import('../views/products/gtr.vue')
  },
  {
    path: '/products/gtr-iron',
    name: 'gtr-iron',
    component: () => import('../views/products/gtr-iron.vue')
  },
  {
    path: '/products/gtr-special',
    name: 'gtr-special',
    component: () => import('../views/products/gtr-special.vue')
  },
  {
    path: '/products/gts',
    name: 'gts',
    component: () => import('../views/products/gts.vue')
  },
  {
    path: '/products/health-band-1s',
    name: 'health-band-1s',
    component: () => import('../views/products/health-band-1s.vue')
  },
  {
    path: '/products/health-band-chip',
    name: 'health-band-chip',
    component: () => import('../views/products/health-band-chip.vue')
  },
  {
    path: '/products/health-watch',
    name: 'health-watch',
    component: () => import('../views/products/health-watch.vue')
  },
  {
    path: '/products/jackets',
    name: 'jackets',
    component: () => import('../views/products/jackets.vue')
  },
  {
    path: '/products/moonbeam',
    name: 'moonbeam',
    component: () => import('../views/products/moonbeam.vue')
  },
  {
    path: '/products/leather-wristbands',
    name: 'leather-wristbands',
    component: () => import('../views/products/leather-wristbands.vue')
  },
  {
    path: '/products/long-short-wristbands',
    name: 'long-short-wristbands',
    component: () => import('../views/products/long-short-wristbands.vue')
  },
  {
    path: '/products/nexo-marvel',
    name: 'nexo-marvel',
    component: () => import('../views/products/nexo-marvel.vue')
  },
  {
    path: '/products/nexo',
    name: 'nexo',
    component: () => import('../views/products/nexo.vue')
  },
  {
    path: '/products/pace',
    name: 'pace',
    component: () => import('../views/products/pace.vue')
  },
  {
    path: '/products/probe',
    name: 'probe',
    component: () => import('../views/products/probe.vue')
  },
  {
    path: '/products/powerbuds',
    name: 'powerbuds',
    component: () => import('../views/products/powerbuds.vue')
  },
  {
    path: '/products/powerbuds-pro',
    name: 'powerbuds-pro',
    component: () => import('../views/products/powerbuds-pro.vue')
  },
  {
    path: '/products/rubber-wristbands',
    name: 'rubber-wristbands',
    component: () => import('../views/products/rubber-wristbands.vue')
  },
  {
    path: '/products/silicone-wristbands',
    name: 'silicone-wristbands',
    component: () => import('../views/products/silicone-wristbands.vue')
  },
  {
    path: '/products/sport-wristbands',
    name: 'sport-wristbands',
    component: () => import('../views/products/sport-wristbands.vue')
  },
  {
    path: '/products/stratos-3-starwar',
    name: 'stratos-3-starwar',
    component: () => import('../views/products/stratos-3-starwar.vue')
  },
  {
    path: '/products/stratos-3',
    name: 'stratos-3',
    component: () => import('../views/products/stratos-3.vue')
  },
  {
    path: '/products/stratos-3-lite',
    name: 'stratos-3-lite',
    component: () => import('../views/products/stratos-3-lite.vue')
  },
  {
    path: '/products/stratos',
    name: 'stratos',
    component: () => import('../views/products/stratos.vue')
  },
  {
    path: '/products/stratos+',
    name: 'stratos+',
    component: () => import('../views/products/stratos+.vue')
  },
  {
    path: '/products/single-t-shirt',
    name: 'single-t-shirt',
    component: () => import('../views/products/single-t-shirt.vue')
  },
  {
    path: '/products/t-shirt',
    name: 't-shirt',
    component: () => import('../views/products/t-shirt.vue')
  },
  {
    path: '/products/t-rex',
    name: 't-rex',
    component: () => import('../views/products/t-rex.vue')
  },
  {
    path: '/products/t-rex2',
    name: 't-rex2',
    component: () => import('../views/products/t-rex2.vue')
  },
  {
    path: '/products/t-rex-pro',
    name: 't-rex-pro',
    component: () => import('../views/products/t-rex-pro.vue')
  },
  {
    path: '/products/happya',
    name: 'happya',
    component: () => import('../views/products/hollywood.vue')
  },
  {
    path: '/products/gts3',
    name: 'gts3',
    component: () => import('../views/products/gts3.vue')
  },
  {
    path: '/products/gtr3',
    name: 'gtr3',
    component: () => import('../views/products/gtr3.vue')
  },
  {
    path: '/products/gtr3-pro',
    name: 'gtr3-pro',
    component: () => import('../views/products/gtr3-pro.vue')
  },
  {
    path: '/products/verge-lite',
    name: 'verge-lite',
    component: () => import('../views/products/verge-lite.vue')
  },
  {
    path: '/products/verge',
    name: 'verge',
    component: () => import('../views/products/verge.vue')
  },
  {
    path: '/products/vip-card',
    name: 'vip-card',
    component: () => import('../views/products/vip-card.vue')
  },
  {
    path: '/products/zhuarong',
    name: 'zhuarong',
    component: () => import('../views/products/zhuarong.vue')
  },
  {
    path: '/products/zenbuds',
    name: 'zenbuds',
    component: () => import('../views/products/zenbuds.vue')
  },
  {
    path: '/services',
    name: 'services',
    component: () => import('../views/services/Index.vue')
  },
  {
    path: '/services/strategy',
    name: 'strategy',
    component: () => import('../views/services/Strategy.vue')
  },
  {
    path: '/services/accessories',
    name: 'accessories',
    component: () => import('../views/services/Accessories.vue')
  },
  {
    path: '/services/course',
    name: 'course',
    component: () => import('../views/services/Course.vue')
  },
  {
    path: '/services/care',
    name: 'care',
    component: () => import('../views/services/Care.vue')
  },
  {
    path: '/services/door',
    name: 'door',
    component: () => import('../views/services/Door.vue')
  },
  {
    path: '/services/band',
    name: 'band_strategy',
    component: () => import('../views/services/Band.vue')
  },
  {
    path: '/services/watch',
    name: 'watch_strategy',
    component: () => import('../views/services/Watch.vue')
  },
  {
    path: '/services/earphone',
    name: 'earphone_strategy',
    component: () => import('../views/services/Earphone.vue')
  },
  {
    path: '/services/treadmill',
    name: 'treadmill_strategy',
    component: () => import('../views/services/Treadmill.vue')
  },
  {
    path: '/services/sport',
    name: 'sport_strategy',
    component: () => import('../views/services/Sport.vue')
  },
  {
    path: '/services/course/:id',
    name: 'course-detail',
    component: () => import('../views/services/CourseDetail.vue')
  },
  {
    path: '/services/download',
    name: 'download',
    component: () => import('../views/services/Download.vue')
  },
  {
    path: '/services/repair',
    name: 'repair',
    component: () => import('../views/services/Repair.vue')
  },
  {
    path: '/services/repair-list',
    name: 'repair-list',
    component: () => import('../views/services/RepairList.vue')
  },
  {
    path: '/services/login',
    name: 'login',
    component: () => import('../views/services/Login.vue')
  },
  {
    path: '/services/query-method',
    name: 'query-method',
    component: () => import('../views/services/QueryMethod.vue')
  },
  {
    path: '/services/store',
    name: 'store',
    component: () => import('../views/services/Store.vue')
  },
  // ces路由
  {
    path: '/ces',
    name: 'ces',
    component: () => import('../views/Ces.vue')
  },
  // 新闻路由
  {
    path: '/news',
    name: 'news',
    component: () => import(/* webpackChunkName: "about" */ '../views/News.vue')
  },
  // 新闻详情
  {
    path: '/news-detail',
    name: 'news-detail',
    component: () => import(/* webpackChunkName: "about" */ '../views/NewsDetail.vue')
  },
  // bip s
  {
    path: '/products/bip-s',
    name: 'bip-s',
    component: () => import(/* webpackChunkName: "about" */ '../views/products/bip-s.vue')
  },
  // 坚韧款表带
  {
    path: '/products/sport-firm',
    name: 'sport-firm',
    component: () => import(/* webpackChunkName: "about" */ '../views/products/sport-firm.vue')
  },
  // 商务运动表带
  {
    path: '/products/business-sport',
    name: 'business-sport',
    component: () => import(/* webpackChunkName: "about" */ '../views/products/business-sport.vue')
  },
  // 氟橡胶轻薄款
  {
    path: '/products/rubber-slim-wristbands',
    name: 'rubber-slim-wristbands',
    component: () => import(/* webpackChunkName: "about" */ '../views/products/rubber-slim-wristbands.vue')
  },
  // 氟橡胶轻盈透气款
  {
    path: '/products/slim-breath',
    name: 'slim-breath',
    component: () => import(/* webpackChunkName: "about" */ '../views/products/slim-breath.vue')
  },
  // ares
  {
    path: '/products/ares',
    name: 'ares',
    component: () => import(/* webpackChunkName: "about" */ '../views/products/ares.vue')
  },
  // antelope2
  {
    path: '/products/antelope2',
    name: 'antelope2',
    component: () => import(/* webpackChunkName: "about" */ '../views/products/antelope2.vue')
  },
  {
    path: '/huangshan2',
    name: 'huangshan2',
    component: () => import(/* webpackChunkName: "about" */ '../views/Huangshan2.vue')
  },
  {
    path: '/products/neo',
    name: 'neo',
    component: () => import(/* webpackChunkName: "about" */ '../views/products/neo.vue')
  },
  {
    path: '/products/gtr2',
    name: 'gtr2',
    component: () => import(/* webpackChunkName: "about" */ '../views/products/gtr2.vue')
  },
  {
    path: '/products/gts2',
    name: 'gts2',
    component: () => import(/* webpackChunkName: "about" */ '../views/products/gts2.vue')
  },
  {
    path: '/products/gts2-mini',
    name: 'gts2-mini',
    component: () => import(/* webpackChunkName: "about" */ '../views/products/gts2-mini.vue')
  },
  {
    path: '/products/gtr2e-sim',
    name: 'gtr2e-sim',
    component: () => import(/* webpackChunkName: "about" */ '../views/products/gtr2e-sim.vue')
  },
  {
    path: '/products/gtr2e',
    name: 'gtr2e',
    component: () => import(/* webpackChunkName: "about" */ '../views/products/gtr2e.vue')
  },
  {
    path: '/products/gts2e',
    name: 'gts2e',
    component: () => import(/* webpackChunkName: "about" */ '../views/products/gts2e.vue')
  },
  {
    path: '/products/pop',
    name: 'pop',
    component: () => import(/* webpackChunkName: "about" */ '../views/products/pop.vue')
  },
  // {
  //   path: '/services/customer-service',
  //   name: 'customer-service',
  //   component: () => import('../views/services/CustomerService.vue')
  // },
  {
    path: '/services/compensation',
    name: 'compensation',
    component: () => import('../views/services/Compensation.vue')
  },
  {
    path: '/services/faq',
    name: 'faq',
    component: () => import('../views/services/Faq.vue')
  },
  {
    path: '/services/faq-detail',
    name: 'faq-detail',
    component: () => import('../views/services/FaqDetail.vue')
  },
  {
    path: '/products/gtr4',
    name: 'gtr4',
    component: () => import(/* webpackChunkName: "gtr4" */ '../views/products/gtr4.vue')
  },
  {
    path: '/products/gts4',
    name: 'gts4',
    component: () => import(/* webpackChunkName: "gts4" */ '../views/products/gts4.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

// vue router
router.beforeEach((to, from, next) => {
  if (!to.path.includes('services')) {
    window.location.href = 'https://cn.amazfit.com/wx-qrcode.html'
    return
  }
  let path = to.fullPath
  let rpath = from.fullPath
  let pathArr = []
  let pathArrStr = sessionStorage.getItem('pathArrStr') ? sessionStorage.getItem('pathArrStr') : ''
  if (pathArrStr.length > 0) {
    pathArr = pathArrStr.split(',')
    if (!pathArr.includes(path)) {
      pathArr.push(path)
    }
  } else {
    pathArr.push(rpath)
    pathArr.push(path)
  }
  pathArrStr = pathArr.join(',')
  sessionStorage.setItem('pathArrStr', pathArrStr)
  // 统计代码
  if (to.path) window._hmt.push(['_trackPageview', '/#' + to.fullPath])
  next()
})

router.afterEach((to, from, next) => {
  window.scrollTo(0, 0)
})

export default router
