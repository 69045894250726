<template>
  <div class="home-category-item content-padding" :style="{backgroundImage: `url(${backgroundImage})`}">
    <h2 :style="{borderColor: titleColor, color: titleColor}">{{ title }} <span>{{ subTitle }}</span></h2>
    <div class="row" :class="{'one-item': row.length==1, 'two-item': row.length==2, first: index===0}"  v-for="(row, index) in data" :key="index">
      <div class="item" v-for="(item, index) in row" :key="index" @click="$router.push(item.url)">
        <article>
          <div class="name" v-html="item.name"></div>
          <div class="slogan">{{ item.slogan }}</div>
          <div class="price" v-if="item.price">
            <span class="currency">¥</span>
            {{ item.price }}
            <span v-if="item.priceStart" class="start">起</span>
          </div>
          <div class="coming-soon" v-if="item.comingSoon">即将上市，敬请期待</div>
        </article>
        <img :src="item.image" alt="" style="backgroundColor:white">
      </div>
    </div>
    <div class="row">
      <a class="more" @click="clickMore">查看更多 ></a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'home-category-item',
  components: {
  },
  props: {
    title: {
      type: String,
      required: true
    },
    subTitle: {
      type: String,
      required: false
    },
    titleColor: {
      type: String
    },
    backgroundImage: {
      type: String
    },
    data: {
      type: Array,
      required: true
    },
    link: {
      type: String,
      default: '/'
    }
  },
  methods: {
    clickMore () {
      this.$emit('click-more')
    }
  }
}
</script>

<style lang="scss">
.home-category-item {
  position: relative;
  background: no-repeat top;
  background-size: 100% auto;

  h2 {
    position: absolute;
    top: 19px;
    left: 12.5px;
    line-height: 15px;
    font-size: 15px;
    border-left: 2px solid;
    padding-left: 10px;

    span {
      font-size: 0.9em;
    }
  }

  .row {
    display: flex;
    margin-top: 10px;

    &.first {
      margin-top: 100px - 12.5px;
    }

    .item {
      position: relative;
      overflow: hidden;
      border: 1px solid rgba(0, 0, 0, 0.07);
      border-radius:5px;

      img {
        width: 100%;
        display: block;
      }

      .coming-soon {
        margin-top: 25px;
        color: #E60033;
        font-size: 12px;
        line-height: 100%;
      }
    }

    &.one-item {
      .item {
        width: 100%;
      }

      article {
        position: absolute;
        left: 29.5px;
        top: 46.5px;
      }

      .name {
        font-size: 15px;
        font-weight: bold;
        line-height: 100%;

        span {
          font-size: 12px;
        }
      }

      .slogan {
        margin-top: 5px;
        font-size: 10px;
        line-height: 100%;
        color: #666;
      }

      .price {
        margin-top: 20px;
        font-size: 18px;
        line-height: 100%;
      }
    }

    &.two-item {
      .item {
        width: 170px;
        text-align: center;

        &:first-child {
          margin-right: 10px;
        }

        .name {
          padding: 0 10px;
          box-sizing: border-box;
          position: absolute;
          top: 125px;
          font-size: 12px;
          font-weight: bold;
          width: 100%;
          word-break: keep-all;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .slogan {
          position: absolute;
          top: 142.5px;
          width: 100%;
          font-size:10px;
          color: #666;
        }

        .price {
          position: absolute;
          top: 162px;
          color: #E60033;
          font-size: 15px;
          vertical-align: top;
          width: 100%;

          .currency {
            font-size: 9px;
          }
        }
      }
    }
  }

  .more {
    display: block;
    width: 100%;
    text-align: center;
    height: 40px;
    background: rgba(255,255,255,1);
    border: 1px solid rgba(0, 0, 0, 0.07);
    border-radius: 5px;
    font-size: 12px;
    line-height: 40px;
    color: #010101;
  }
}
</style>
