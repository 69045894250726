const CDN_URL = process.env.VUE_APP_CDN_URL
const RELATIVED_PRODUCTS = [
  {
    name: 'Amazfit 表带<br/>炫彩系列',
    image: CDN_URL + '/images/product/accessory/03.png',
    price: 59,
    link: '/products/silicone-wristbands'
  },
  {
    name: 'Amazfit 表带<br/>氟橡胶系列',
    image: CDN_URL + '/images/product/accessory/01.png',
    price: 129,
    link: '/products/rubber-wristbands'
  },
  {
    name: 'Amazfit 表带<br/>真皮系列',
    image: CDN_URL + '/images/product/accessory/04.png',
    price: 129,
    link: '/products/leather-wristbands'
  },
  {
    name: '米动手表青春版<br/>彩色腕带',
    image: CDN_URL + '/images/product/accessory/02.png',
    price: 49,
    link: '/products/bip-colorful-wristbands'
  }
]
export {
  CDN_URL,
  RELATIVED_PRODUCTS
}
