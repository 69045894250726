import Vue from 'vue'
import VueAnalytics from 'vue-analytics'
import VueCountdown from '@chenfengyuan/vue-countdown'

import * as Sentry from '@sentry/browser'
import * as Integrations from '@sentry/integrations'

import App from './App.vue'
import router from './router'
import store from './store'

import 'lib-flexible'

import './plugins/axios'
import {
  Swipe,
  SwipeItem,
  Grid,
  GridItem,
  Sidebar,
  SidebarItem,
  NavBar,
  Tab,
  Tabs,
  Image,
  Lazyload,
  Icon,
  Sticky,
  Picker,
  Popup,
  Button,
  Field,
  Dialog,
  Toast,
  CellGroup,
  DatetimePicker,
  Uploader,
  List,
  PullRefresh,
  Panel,
  Overlay,
  RadioGroup,
  Radio,
  Checkbox,
  CheckboxGroup,
  NoticeBar,
  Col,
  Row,
  Search,
  Collapse,
  CollapseItem
} from 'vant'

Vue.use(Swipe)
  .use(SwipeItem)
  .use(Grid)
  .use(GridItem)
  .use(Sidebar)
  .use(SidebarItem)
  .use(NavBar)
  .use(Tab)
  .use(Tabs)
  .use(Image)
  .use(Lazyload)
  .use(Icon)
  .use(Sticky)
  .use(Picker)
  .use(Popup)
  .use(Button)
  .use(Field)
  .use(Dialog)
  .use(Toast)
  .use(CellGroup)
  .use(DatetimePicker)
  .use(Uploader)
  .use(List)
  .use(PullRefresh)
  .use(Panel)
  .use(Overlay)
  .use(Uploader)
  .use(RadioGroup)
  .use(Radio)
  .use(CheckboxGroup)
  .use(Checkbox)
  .use(NoticeBar)
  .use(Col)
  .use(Row)
  .use(Search)
  .use(Collapse)
  .use(CollapseItem)

Vue.component(VueCountdown.name, VueCountdown)

Vue.config.productionTip = false

if (process.env.NODE_ENV === 'production') {
  Vue.use(VueAnalytics, {
    id: 'UA-123584706-1',
    router
  })

  Sentry.init({
    dsn: 'https://e60ba0ca8898419bbd65e38e0276ae5c@sentry-cn.huami.com//98',
    integrations: [new Integrations.Vue({ Vue, attachProps: true })]
  })
}
Vue.prototype.$setgoindex = function () {
  if (window.history.length <= 1) {
    if (location.href.indexOf('?') === -1) {
      window.location.href = location.href + '?goindex=true'
    } else if (location.href.indexOf('?') !== -1 && location.href.indexOf('goindex') === -1) {
      window.location.href = location.href + '&goindex=true'
    }
  }
}

function isMobile () {
  if (window.navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
    return true
  } else {
    return false
  }
}

if (!isMobile()) {
  location.href = 'https://cn.amazfit.com/'
} else {
  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount('#app')
}
