<template>
  <div id="app">
    <router-view/>

    <div class="go-top" :class="{show: showGoTop}" @click="goTop">
      <img src="https://site-cdn.huami.com/files/amazfit/mobile/assets/go-top.png" alt="">
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      showGoTop: false
    }
  },
  mounted () {
    window.addEventListener('scroll', () => {
      var top = document.documentElement.scrollTop + document.body.scrollTop

      if (top > 700) {
        this.showGoTop = true
      } else {
        this.showGoTop = false
      }
    })
  },
  methods: {
    goTop () {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }
  }
}
</script>

<style lang="scss">
body {
  text-size-adjust: none;
}

#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  color: #333;
}

.clearfix:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0;
}

@font-face {
  font-family: "Lato-Bold";
  src: url('https://site-cdn.huami.com/files/common/fonts/Lato-Bold_5ff7d8311e7b3.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

img {
  max-width: 100%;
}

.content-padding {
  padding: 12.5px;
}

.go-top {
  display: none;
  position: fixed;
  padding: 3px 4px;
  right: 12.5px;
  bottom: 60px;
  background:rgba(255,255,255,1);
  border:1px solid rgba(0, 0, 0, 0.07);
  border-radius:5px;

  &.show {
    display: block;
  }

  img {
    height: 20px;
  }
}

.price {
  color: #E60033;
  font-size: 18px;
  line-height: 100%;
  font-family: Lato-Bold;

  .currency {
    vertical-align: text-top;
    font-size: 0.5em;
  }

  .start {
    vertical-align: text-top;
    font-size: 0.5em;
  }
}

// vant
.van-tabs__line {
  background-color: #F4C751;
}

.van-icon::before {
  color: #333;
}

.van-swipe__indicator {
  margin: 3px 8px;
  width: 3px;
  height: 3px;
  background: #000;
  border-radius: 50%;
  opacity: 1;
}

.van-swipe__indicator--active {
  margin: 0 5px;
  width: 6px;
  height: 6px;
  background: #FFF;
  border: 1px solid #000;
  border-radius: 50%;
}

.van-button.submit {
  background-color: #fcc800;
  border-color: #fcc800;
  border-radius: 10px;
  color: #FFF;
}
</style>
