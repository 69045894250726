'use strict'

import Vue from 'vue'
import axios from 'axios'
import router from '../router'

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
axios.defaults.headers.common['X-Access-Token'] = localStorage.getItem('amafit-token')
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

let config = {
  // baseURL: process.env.baseURL || process.env.apiUrl || ''
  baseURL: 'https://sale.huami.com/v2'
  // timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
}

const _axios = axios.create(config)

_axios.interceptors.request.use(
  (config) => {
    // Do something before request is sent
    return config
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error)
  }
)

// Add a response interceptor
_axios.interceptors.response.use(
  (response) => {
    // Do something with response data
    return response
  },
  (error) => {
    const originalRequest = error.config
    if (!originalRequest._retry && error.response) {
      if (error.response.status) {
        switch (error.response.status) {
          case 401:
            // 401 清除token信息并跳转到登录页面
            // store.dispatch('logout')

            router.replace({
              path: '/services/login',
              query: { redirect: router.currentRoute.fullPath }
            })
            return new Promise(() => {})
        }
      }
    }
    return Promise.reject(error)
  }
)

Plugin.install = (Vue, options) => {
  Vue.axios = _axios
  window.axios = _axios
  Object.defineProperties(Vue.prototype, {
    axios: {
      get () {
        return _axios
      }
    },
    $http: {
      get () {
        return _axios
      }
    }
  })
}

Vue.use(Plugin)

export default Plugin
