import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    index: 0,
    indexClass: 0
  },
  mutations: {
    editActiveTab (state, value) {
      state.index = value
    },
    editIndexClass (state, value) {
      state.indexClass = value
    }
  },
  actions: {
    editActiveTabFun ({ commit }, data) {
      commit('editActiveTab', data)
    },
    editIndexClassFun ({ commit }, data) {
      commit('editIndexClass', data)
    }
  },
  modules: {
  }
})
