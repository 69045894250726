<template>
  <div class="home-page">
    <van-sticky>
      <header>
        <img class="logo" src="https://site-cdn.huami.com/files/amazfit/cn/rebranding/amazfit_logo2.png" alt="">

        <div class="nav">
          <div class="wrap">
            <a @click="changeTab(index)" class="item" :class="{current: activeTab==index}" v-for="(nav, index) in navs" :key="nav">{{ nav }}</a>
          </div>
        </div>
        <div class="all-products"><router-link to="/category">所有产品 ></router-link></div>
      </header>
    </van-sticky>

    <div v-if="activeTab==0">
      <div class="content-padding">
        <div class="swiper">
          <van-swipe :autoplay="300000">
            <van-swipe-item v-for="(item, index) in swiperData" :key="index" @click="goLocation(item.url)">
              <img :src="item.image" alt="">
            </van-swipe-item>
          </van-swipe>
        </div>
        <div class="hot-products">
          <div class="item" v-for="(item, index) in hotProducts" :key="index"  @click="$router.push(item.url)">
            <img :src="item.image" alt="">
            <div class="name" v-html="item.name"></div>
          </div>
        </div>
      </div>
      <home-category-item title="智能手表" sub-title="都市时尚" title-color="#2F3D4B" :background-image="fashionSmartWatchBg" :data="fashionSmartWatchs" @click-more="changeTab(1, 1)"></home-category-item>
      <home-category-item title="智能手表" sub-title="专业运动" title-color="#FFF" :background-image="sportWatchBg" :data="sportWatchs" @click-more="changeTab(1, 2)"></home-category-item>
      <home-category-item title="智能手表" sub-title="户外探索" title-color="#FFF" :background-image="outdoorWatchBg" :data="outdoorWatchs" @click-more="changeTab(1, 3)"></home-category-item>
      <home-category-item title="智能手表" sub-title="全能旗舰" title-color="#FFF" :background-image="smartWatchBg" :data="smartWatchs" @click-more="changeTab(1, 0)"></home-category-item>
      <home-category-item title="智能手表" sub-title="经典入门" title-color="#FFF" :background-image="baseSmartWatchBg" :data="baseSmartWatchs" @click-more="changeTab(1, 4)"></home-category-item>
      <home-category-item title="智能手表" sub-title="X 系列" title-color="#FFF" :background-image="xWatchBg" :data="xWatchs" @click-more="changeTab(1,6)"></home-category-item>
      <home-category-item title="儿童手表" title-color="#333"  :background-image="kidsWatchBg" :data="kidsWatches" @click-more="changeTab(3)"></home-category-item>
      <home-category-item title="耳机" title-color="#FFF" :background-image="earphoneBg" :data="earphones" @click-more="changeTab(3)"></home-category-item>
      <home-category-item title="配件" title-color="#FFF" :background-image="clothesBg" :data="chothes" @click-more="changeTab(4)"></home-category-item>
    </div>
    <div v-else-if="activeTab==1" class="content-padding">
      <div class="swiper">
        <router-link :to="cateWatch.url"><img :src="cateWatch.image" :alt="cateWatch.image_alt"></router-link>
      </div>
      <van-sticky :offset-top="headerHeight" class="category-nav">
        <div class="container">
          <template v-for="(products, index) in categoryWatch">
            <a class="" :key="index" @click="goSection(index)" :class="{current: activeSubCate==index}">{{ products.title }}</a>
          </template>
        </div>
      </van-sticky>
      <category-item :title="products.title" :data="products.products" v-for="(products, index) in categoryWatch" :key="index"></category-item>
    </div>
    <!-- 儿童手表 -->
    <div v-else-if="activeTab==2" class="content-padding">
      <div class="swiper">
        <router-link :to="kidsWatch.url"><img :src="kidsWatch.image" :alt="kidsWatch.image_alt"></router-link>
      </div>
      <category-item :title="products.title" :data="products.products" v-for="(products, index) in categoryKidsWatch" :key="index"></category-item>
    </div>
    <!-- 耳机 -->
    <div v-else-if="activeTab==3" class="content-padding">
      <div class="swiper">
        <router-link :to="cateEarphone.url"><img :src="cateEarphone.image" :alt="cateEarphone.image_alt"></router-link>
      </div>
      <category-item :title="products.title" :data="products.products" v-for="(products, index) in categoryEarphone" :key="index"></category-item>
    </div>
    <!-- 配件 -->
    <div v-else-if="activeTab=4" class="content-padding">
      <div class="swiper">
        <router-link :to="cateSport.url"><img :src="cateSport.image" :alt="cateSport.image_alt"></router-link>
      </div>
      <category-item :title="products.title" :data="products.products" v-for="(products, index) in categorySport" :key="index"></category-item>
    </div>

    <Footer></Footer>
  </div>
</template>

<script>
import BScroll from 'better-scroll'

import { CDN_URL } from '../config'

import HomeCategoryItem from '../components/HomeCategoryItem'
import CategoryItem from '../components/CategoryItem'
import { mapState, mapActions } from 'vuex'
import Footer from '../components/Footer'

export default {
  name: 'home',
  components: {
    HomeCategoryItem,
    CategoryItem,
    Footer
  },
  data () {
    return {
      navs: [
        '首页',
        '智能手表',
        '儿童手表',
        '耳机'
      ],
      activeTab: 0,
      activeSubCate: 0,
      headerHeight: 0,
      swiperData: [],
      hotProducts: [
        {
          name: 'Amazfit <br />GTR 4',
          image: 'https://site-cdn.huami.com/files/amazfit/mobile/images/product/gtr4/hots-gtr4.png',
          url: '/products/gtr4'
        },
        {
          name: 'Amazfit <br />GTS 4',
          image: 'https://site-cdn.huami.com/files/amazfit/mobile/images/product/gts4/hots-gts4.png',
          url: '/products/gts4'
        },
        {
          name: 'Amazfit <br />GTR 3 Pro',
          image: 'https://site-cdn.huami.com/files/amazfit/cn/poster/gtr3-pro.png',
          url: '/products/gtr3-pro'
        },
        {
          name: 'Amazfit <br />GTR 3',
          image: 'https://site-cdn.huami.com/files/amazfit/cn/poster/黑色220.png',
          url: '/products/gtr3'
        }
      ],
      fashionSmartWatchBg: 'https://site-cdn.huami.com/files/amazfit/mobile/common/cate-fashion.png',
      fashionSmartWatchs: [
        [
          {
            name: 'Amazfit GTR 4',
            slogan: '全能表率',
            image: 'https://site-cdn.huami.com/files/amazfit/mobile/images/product/gtr4/home-gtr4.jpg',
            price: 1199,
            url: '/products/gtr4'
          }
        ],
        [
          {
            name: 'Amazfit GTS 4',
            slogan: '全能表率',
            image: 'https://site-cdn.huami.com/files/amazfit/mobile/images/product/gts4/home-gts4.jpg',
            price: 1199,
            url: '/products/gts4'
          }
        ],
        [
          {
            name: 'Amazfit GTR 3 Pro',
            slogan: '突破经典，释放未来',
            image: 'https://site-cdn.huami.com/files/amazfit/cn/m/home-gtr3pro.jpg',
            price: 1099,
            url: '/products/gtr3-pro'
          }
        ],
        [
          {
            name: 'Amazfit GTR 3',
            slogan: '突破经典，释放未来',
            image: 'https://site-cdn.huami.com/files/amazfit/cn/m/home-gtr3.jpg',
            price: 899,
            url: '/products/gtr3'
          }
        ],
        [
          {
            name: 'Amazfit GTS 3',
            slogan: '轻薄随心，出手不凡',
            image: 'https://site-cdn.huami.com/files/amazfit/cn/m/home-gts3.jpg',
            price: 899,
            url: '/products/gts3'
          }
        ],
        [
          {
            name: 'Amazfit GTR 2 eSIM',
            slogan: '独立通话，自由来电',
            image: 'https://site-cdn.huami.com/files/amazfit/mobile/common/poster-gtr2e-sim.jpg',
            price: 1999,
            url: '/products/gtr2e-sim'
          }
        ],
        [
          {
            name: 'Amazfit GTS 2e',
            slogan: '出色，溢于言表',
            image: 'https://site-cdn.huami.com/files/amazfit/mobile/common/poster-gts2e-mb.jpg',
            price: 799,
            url: '/products/gts2e'
          }
        ],
        [
          {
            name: 'Amazfit GTR 2e',
            slogan: '重塑经典 ，「腕」若新生',
            image: 'https://site-cdn.huami.com/files/amazfit/mobile/common/poster-gtr2e-mb.jpg',
            price: 799,
            url: '/products/gtr2e'
          }
        ],
        [
          {
            name: 'Amazfit GTS 2 mini',
            slogan: '精彩，全面呈现',
            image: 'https://site-cdn.huami.com/files/amazfit/mobile/common/poster-gts2-mini-mb.jpg',
            price: 699,
            url: '/products/gts2-mini'
          }
        ],
        // [
        //   {
        //     name: 'Amazfit GTS 2',
        //     slogan: '出色，溢于言表',
        //     image: 'https://site-cdn.huami.com/files/amazfit/amazfit-m/common/poster-gts2-mb.jpg',
        //     price: 999,
        //     url: '/products/gts2'
        //   }
        // ],
        // [
        //   {
        //     name: 'Amazfit GTR 2',
        //     slogan: '重塑经典 ，「腕」若新生',
        //     image: 'https://site-cdn.huami.com/files/amazfit/amazfit-m/common/poster-gtr2-mb.jpg',
        //     price: 999,
        //     url: '/products/gtr2'
        //   }
        // ],
        [
          {
            name: 'Amazfit GTR',
            slogan: '续航跃迁，设计回归',
            image: CDN_URL + '/images/home/fashion-smart-watch/gtr.jpg',
            price: 799,
            priceStart: true,
            url: '/products/gtr'
          },
          {
            name: 'Amazfit GTR 钢铁侠系列限量版',
            slogan: '续航革命，设计回归',
            image: CDN_URL + '/images/home/fashion-smart-watch/gtr-iron.jpg',
            price: 1399,
            url: '/products/gtr-iron'
          }
        ]
      ],
      sportWatchBg: CDN_URL + '/images/home/sport-watch/bg.png',
      sportWatchs: [
        [
          {
            name: 'Amazfit 智能运动手表 3<br/><span>精英版</span>',
            slogan: '双擎动力，突破极限',
            image: CDN_URL + '/images/home/sport-watch/stratos-3-lite.jpg',
            price: 1699,
            url: '/products/stratos-3-lite'
          }
        ],
        [
          {
            name: 'Amazfit 智能运动手表 3<br/><span>星球大战系列限量版</span>',
            slogan: '双擎动力，突破极限，原力与你同在',
            image: CDN_URL + '/images/home/sport-watch/stratos-3-starwar.jpg',
            price: 1699,
            url: '/products/stratos-3-starwar'
          }
        ],
        [
          {
            name: 'Amazfit 智能运动手表 3',
            slogan: '双擎动力，突破极限',
            image: CDN_URL + '/images/home/sport-watch/stratos-3-1.jpg',
            price: 1299,
            url: '/products/stratos-3'
          },
          {
            name: 'Amazfit 智能运动手表 2',
            slogan: '一面运动，一面商务',
            image: CDN_URL + '/images/home/sport-watch/stratos.jpg',
            price: 999,
            url: '/products/stratos'
          }
        ]
      ],
      baseSmartWatchBg: 'https://site-cdn.huami.com/files/amazfit/mobile/common/cate-basic.png',
      baseSmartWatchs: [
        [
          {
            name: 'Amazfit Pop',
            slogan: '轻彩随行，把握健康',
            image: 'https://site-cdn.huami.com/files/amazfit/mobile/common/poster-mb-pop-1.jpg',
            price: 349,
            url: '/products/pop'
          }
        ],
        [
          {
            name: 'Amazfit <br>米动手表青春版1S',
            slogan: '轻盈多彩',
            image: CDN_URL + '/images/home/base-smart-watch/bip-s.jpg',
            price: 399,
            url: '/products/bip-s'
          }
        ],
        [
          {
            name: 'Amazfit 米动手表青春版',
            slogan: '跑开心',
            image: CDN_URL + '/images/home/base-smart-watch/bip.jpg',
            price: 399,
            url: '/products/bip'
          },
          {
            name: 'Amazfit 米动手表青春版 Lite',
            slogan: '智能手表全民化',
            image: CDN_URL + '/images/home/base-smart-watch/bip-lite.jpg',
            price: 299,
            url: '/products/bip-lite'
          }
        ]
      ],
      smartWatchBg: 'https://site-cdn.huami.com/files/amazfit/mobile/common/cate-watch.png',
      smartWatchs: [
        [
          {
            name: 'Amazfit 智能手表 2<br/><span>复仇者联盟系列限量版</span>',
            slogan: '向世界重新说“你好”',
            image: CDN_URL + '/images/home/smart-watch/nexo-marvel.jpg',
            price: 1499,
            url: '/products/nexo-marvel'
          }
        ],
        [
          {
            name: 'Amazfit 智能手表',
            slogan: '让有趣，更久一点',
            image: CDN_URL + '/images/home/smart-watch/verge.jpg',
            price: 799,
            url: '/products/verge'
          },
          {
            name: 'Amazfit 智能手表青春版',
            slogan: '靓屏长续航 青春更出彩',
            image: CDN_URL + '/images/home/smart-watch/verge-lite.jpg',
            price: 499,
            url: '/products/verge-lite'
          }
        ]
      ],
      healthWatchBg: CDN_URL + '/images/home/health-watch/bg.png',
      outdoorWatchBg: 'https://site-cdn.huami.com/files/amazfit/mobile/common/cate-sport.png',
      outdoorWatchs: [
        [
          {
            name: 'Amazfit T-Rex Pro',
            slogan: '探索，让生活更自由',
            image: 'https://site-cdn.huami.com/files/amazfit/mobile/common/cate-t-rex-pro.jpeg',
            price: 1099,
            url: '/products/t-rex-pro'
          }
        ],
        [
          {
            name: 'Amazfit Neo',
            slogan: '潮流所向，岂止于型',
            image: CDN_URL + '/images/home/out-watch/neo.png',
            price: 299,
            url: '/products/neo'
          }
        ],
        [
          {
            name: 'Amazfit Ares',
            slogan: '都市户外 八面威风',
            image: CDN_URL + '/images/home/out-watch/ares-1.png',
            price: 499,
            url: '/products/ares'
          },
          {
            name: 'Amazfit T-rex',
            slogan: '经久不衰 征战旷野',
            image: CDN_URL + '/images/home/out-watch/t-rex-1.png',
            price: 799,
            url: '/products/t-rex'
          }
        ]
      ],
      xWatchBg: CDN_URL + '/images/home/x-series/bg.png',
      xWatchs: [
        [
          {
            name: 'Amazfit X',
            slogan: 'Bow to the Future',
            image: CDN_URL + '/images/home/x-series/amazfit-x.jpg',
            price: 1999,
            url: '/products/amazfit-x'
          }
        ]
      ],
      // bandBg: CDN_URL + '/images/home/band/bg.png',
      // bands: [
      //   [
      //     {
      //       name: 'Amazfit 月霜手环',
      //       slogan: '天生优雅 时尚百搭',
      //       image: CDN_URL + '/images/home/band/yueshuang.jpg',
      //       price: 299,
      //       url: '/products/moonbeam'
      //     },
      //     {
      //       name: '米动健康手环 黄山1号版',
      //       slogan: '随时随地测心电图',
      //       image: CDN_URL + '/images/home/health/health-band-1s.jpg',
      //       price: 399,
      //       url: '/products/health-band-1s'
      //     }
      //   ]
      // ],
      earphoneBg: CDN_URL + '/images/home/earphone/bg.png',
      earphones: [
        [
          {
            name: 'Amazfit PowerBuds Pro',
            slogan: '享静界 乐健康',
            image: 'https://site-cdn.huami.com/files/amazfit/cn/m/飞书20211012-163244.jpg',
            comingSoon: false,
            price: 999,
            url: '/products/powerbuds-pro'
          }
        ],
        [
          {
            name: 'Amazfit PowerBuds',
            slogan: '澎湃音质 随心所动',
            image: CDN_URL + '/images/home/earphone/powerbuds.jpg',
            comingSoon: false,
            price: 699,
            url: '/products/powerbuds'
          }
        ],
        [
          {
            name: 'Amazfit ZenBuds',
            slogan: '入耳入梦',
            image: CDN_URL + '/images/home/earphone/zenbuds.jpg',
            comingSoon: false,
            price: 999,
            url: '/products/zenbuds'
          }
        ]
      ],
      treadmillBg: CDN_URL + '/images/home/treadmill/bg.png',
      kidsWatchBg: 'https://site-cdn.huami.com/files/amazfit/cn/Hollywood/750308.png',
      kidsWatches: [
        [
          {
            name: 'Amazfit<br>快乐鸭儿童健康手表',
            slogan: '体育达标好帮手 保护视力好伙伴',
            image: 'https://site-cdn.huami.com/files/amazfit/cn/Hollywood/700320.png',
            comingSoon: false,
            price: 799,
            url: '/products/happya'
          }
        ]
      ],
      clothesBg: 'https://site-cdn.huami.com/files/amazfit/mobile/common/cate-accessory.png',
      chothes: [
        [
          {
            name: 'Amazfit 表带 氟橡胶系列 轻盈透气款',
            slogan: '20mm | 22mm',
            image: 'https://site-cdn.huami.com/files/amazfit/common/cate/cate-mb-slim-breath-1.jpg',
            price: 129,
            url: '/products/slim-breath'
          },
          {
            name: 'Amazfit 表带 氟橡胶系列',
            slogan: '20mm | 22mm',
            image: CDN_URL + '/images/home/accessory/rubber-wristbands.jpg',
            price: 129,
            url: '/products/rubber-wristbands'
          }
        ]
      ],
      cateHealth: {
        image: '',
        image_alt: '',
        url: ''
      },
      cateWatch: {
        image: '',
        image_alt: '',
        url: ''
      },
      cateEarphone: {
        image: '',
        image_alt: '',
        url: ''
      },
      cateTreadmill: {
        image: '',
        image_alt: '',
        url: ''
      },
      kidsWatch: {
        image: '',
        image_alt: '',
        url: ''
      },
      cateSport: {
        image: '',
        image_alt: '',
        url: ''
      },
      categoryWatch: [
        {
          title: '都市时尚',
          products: [
            [
              {
                name: 'Amazfit GTR 4',
                slogan: '血压健康研究 | 高精度运动心率 | 六星双频精准定位 <br>健身房力量训练 | 竞速流线设计',
                image: 'https://site-cdn.huami.com/files/amazfit/mobile/images/product/gtr4/gtr4-banner1.jpg',
                price: 1199,
                url: '/products/gtr4'
              }
            ],
            [
              {
                name: 'Amazfit GTS 4',
                slogan: '血压健康研究 | 高精度运动心率 | 精致轻薄设计 <br>六星双频精准定位 | 健身房力量训练',
                image: 'https://site-cdn.huami.com/files/amazfit/mobile/images/product/gts4/gts4-banner1.jpg',
                price: 1199,
                url: '/products/gts4'
              }
            ],
            [
              {
                name: 'Amazfit GTR 3 Pro',
                slogan: '数字表冠｜血压健康研究｜全方位健康监测 <br>Zepp OS 丰富手表小程序生态｜12 天超长续航',
                image: 'https://site-cdn.huami.com/files/amazfit/cn/m/gtr3-pro700x350.jpg',
                price: 1099,
                url: '/products/gtr3-pro'
              }
            ],
            [
              {
                name: 'Amazfit GTR 3',
                slogan: '数字表冠｜全方位健康监测｜Zepp OS 丰富<br>手表小程序生态｜21 天超长澎湃续航',
                image: 'https://site-cdn.huami.com/files/amazfit/cn/m/gtr3-700x350.jpg',
                price: 899,
                url: '/products/gtr3'
              }
            ],
            [
              {
                name: 'Amazfit GTS 3',
                slogan: '8.8mm 轻薄时尚设计｜全方位健康监测 <br> Zepp OS丰富手表小程序生态｜12 天超长续航',
                image: 'https://site-cdn.huami.com/files/amazfit/cn/m/gts3-700x350.jpg',
                price: 899,
                url: '/products/gts3'
              }
            ],

            [
              {
                name: 'Amazfit GTR 2 eSIM',
                slogan: '无边界轻薄设计｜血氧饱和度检<br />智能语音助手｜eSIM 独立通信',
                image: 'https://site-cdn.huami.com/files/amazfit/mobile/common/cate-gtr2e-sim.jpg',
                price: 1999,
                url: '/products/gtr2e-sim'
              }
            ],
            [
              {
                name: 'Amazfit GTS 2e',
                slogan: '无边界轻薄设计 ｜ 超视网膜屏幕全天候显示<br>血氧饱和度检测 ｜ 智能语音助手',
                image: 'https://site-cdn.huami.com/files/amazfit/mobile/common/cate-gts2e-mb.jpg',
                price: 799,
                url: '/products/gts2e'
              }
            ],
            [
              {
                name: 'Amazfit GTR 2e',
                slogan: '无边界轻薄设计｜血氧饱和度检测<br />智能语音助手｜强劲续航',
                image: 'https://site-cdn.huami.com/files/amazfit/mobile/common/cate-gtr2e-mb.jpg',
                price: 799,
                url: '/products/gtr2e'
              }
            ],
            [
              {
                name: 'Amazfit GTS 2 mini',
                slogan: '时尚轻薄设计｜血氧饱和度检测<br />70+ 运动模式｜14 天长续航',
                image: 'https://site-cdn.huami.com/files/amazfit/mobile/common/cate-gts2-mini-mb.jpg',
                price: 699,
                url: '/products/gts2-mini'
              }
            ],
            [
              {
                name: 'Amazfit GTS 2',
                slogan: '无边界轻薄设计 ｜ 血氧饱和度检测<br />独立音乐播放 ｜ 智能语音助手',
                image: 'https://site-cdn.huami.com/files/amazfit/amazfit-m/common/cate-gts2-mb.jpg',
                price: 999,
                url: '/products/gts2'
              }
            ],
            [
              {
                name: 'Amazfit GTR 2',
                slogan: '无边界轻薄设计｜血氧饱和度检测<br />独立音乐播放｜强劲续航',
                image: 'https://site-cdn.huami.com/files/amazfit/amazfit-m/common/cate-gtr2-mb.jpg',
                price: 999,
                url: '/products/gtr2'
              }
            ],
            [
              {
                name: 'Amazfit GTS',
                slogan: '炫彩金属表身 | 超视网膜屏幕全天候显示<br/>AI 自动甄别心律不齐（含房颤）| 14 天长续航',
                image: CDN_URL + '/images/watch/gts.jpg',
                price: 899,
                url: '/products/gts'
              }
            ],
            [
              {
                name: 'Amazfit GTR 42mm 璀璨特别版',
                slogan: '奥地利方晶锆石奢华工艺 | 意大利进口牛皮表带<br/> 专属奢华表盘 | 12 天强劲续航',
                image: CDN_URL + '/images/watch/gtr-special-banner.jpg',
                price: 999,
                url: '/products/gtr-special'
              }
            ],
            [
              {
                name: 'Amazfit GTR',
                slogan: '金属陶瓷奢华工艺',
                image: CDN_URL + '/images/watch/gtr.jpg',
                price: 799,
                priceStart: true,
                url: '/products/gtr'
              },
              {
                name: 'Amazfit GTR 钢铁侠系列限量版',
                slogan: '独占金色不锈钢金属机身',
                image: CDN_URL + '/images/watch/gtr-iron.jpg',
                price: 1399,
                url: '/products/gtr-iron'
              }
            ]
          ]
        },
        {
          title: '专业运动',
          products: [
            [
              {
                name: 'Amazfit 智能运动手表3 精英版',
                slogan: '高端钛合金表圈 | 蓝宝石屏幕盖板 <br/> Ultra 模式 14 天长续航 | FIRSTBEAT 专业运动分析',
                image: CDN_URL + '/images/watch/stratos-3-lite.jpg',
                price: 1699,
                url: '/products/stratos-3-lite'
              }
            ],
            [
              {
                name: 'Amazfit 智能运动手表3 星球大战系列限量版',
                slogan: '星球大战专属定制 | 定制全圆反射屏 <br/> Ultra 模式14天长续航 | FIRSTBEAT 专业运动分析',
                image: CDN_URL + '/images/watch/stratos-3-starwar.jpg',
                price: 1699,
                url: '/products/stratos-3-starwar'
              }
            ],
            [
              {
                name: 'Amazfit 智能运动手表 3',
                slogan: '定制全圆反射屏 | Ultra 模式 14 天长续航<br/>FIRSTBEAT 专业运动分析 | 四星三模精准 GPS',
                image: CDN_URL + '/images/watch/stratos-3.jpg',
                price: 1299,
                url: '/products/stratos-3'
              }
            ],
            [
              {
                name: 'Amazfit 智能运动手表 2',
                slogan: '铁人三项 | FIRSTBEAT 专业运动分析<br/>防刮陶瓷表圈 | 5 天长续航',
                image: CDN_URL + '/images/watch/stratos.jpg',
                price: 999,
                url: '/products/stratos'
              }
            ],
            [
              {
                name: 'Amazfit 智能运动手表 2S',
                slogan: '蓝宝石屏幕盖板 | 铁人三项',
                image: CDN_URL + '/images/watch/stratos+.jpg',
                price: 1499,
                url: '/products/stratos+'
              },
              {
                name: 'Amazfit 智能运动手表',
                slogan: '蓝牙听歌 | 陶瓷表圈',
                image: CDN_URL + '/images/watch/pace.jpg',
                price: 799,
                url: '/products/pace'
              }
            ]
          ]
        },
        {
          title: '户外探索',
          products: [
            [
              {
                name: 'Amazfit T-Rex Pro',
                slogan: '100米防水｜15项军规认证<br />四星三模定位',
                image: 'https://site-cdn.huami.com/files/amazfit/en/mobile/classify/watch/t-rex-pro.jpg',
                price: 1099,
                url: '/products/t-rex-pro'
              }
            ],
            [
              {
                name: 'Amazfit Neo',
                slogan: '复古设计 | 24小时心率监测 <br /> 50 米深度防水 | 4 周超长续航',
                image: CDN_URL + '/images/watch/neo.png',
                price: 299,
                url: '/products/neo'
              }
            ],
            [
              {
                name: 'Amazfit Ares',
                slogan: '70 项运动模式 | 14 天长续航',
                image: CDN_URL + '/images/watch/ares.png',
                price: 499,
                url: '/products/ares'
              },
              {
                name: 'Amazfit T-rex',
                slogan: '12 项军规认证 | 户外风格设计',
                image: CDN_URL + '/images/watch/t-rex.png',
                price: 799,
                url: '/products/t-rex'
              }
            ]
          ]
        },
        {
          title: '全能旗舰',
          products: [
            [
              {
                name: 'Amazfit 智能手表 2 复仇者联盟系列限量版',
                slogan: '专属量子战衣配色白陶瓷表身 | 4G LTE 通话上网<br/>2.5D 水漾屏 | 独立 QQ、QQ 音乐',
                image: CDN_URL + '/images/watch/nexo-marvel.jpg',
                price: 1499,
                url: '/products/nexo-marvel'
              }
            ],
            [
              {
                name: 'Amazfit 智能手表',
                slogan: '内置小爱同学',
                image: CDN_URL + '/images/watch/verge.jpg',
                price: 799,
                url: '/products/verge'
              },
              {
                name: 'Amazfit 智能手表青春版',
                slogan: '靓屏长续航 AMOLED炫彩屏',
                image: CDN_URL + '/images/watch/verge-lite.jpg',
                price: 499,
                url: '/products/verge-lite'
              }
            ]
          ]
        },
        {
          title: '经典入门',
          products: [
            [
              {
                name: 'Amazfit Pop',
                slogan: '高清 1.43 英寸彩屏 | 血氧检测 <br /> 60+ 运动模式 | 5ATM 防水',
                image: 'https://site-cdn.huami.com/files/amazfit/mobile/common/cate-mb-pop.jpg',
                price: 349,
                url: '/products/pop'
              }
            ],
            [
              {
                name: 'Amazfit 米动手表青春版1S',
                slogan: '轻盈多彩表身 | 30 天超长续航 <br/> huami- PAI<sup style="font-size:12px">TM</sup>健康评估系统 | GPS 双星定位',
                image: CDN_URL + '/images/watch/bip-s.jpg',
                price: 399,
                url: '/products/bip'
              }
            ],
            [
              {
                name: 'Amazfit 米动手表青春版',
                slogan: '续航 45 天 | 32 克无感佩戴',
                image: CDN_URL + '/images/watch/bip.jpg',
                price: 399,
                url: '/products/bip'
              },
              {
                name: 'Amazfit 米动手表青春版 Lite',
                slogan: '续航 45 天 | 全天心率',
                image: CDN_URL + '/images/watch/bip-lite.jpg',
                price: 299,
                url: '/products/bip-lite'
              }
            ]
          ]
        },
        {
          title: 'X 系列',
          products: [
            [
              {
                name: 'Amazfit X',
                slogan: '3D曲面屏幕 | 钛合金机身 | 心率血氧监测',
                image: CDN_URL + '/images/watch/amazfit-x-banner.jpg',
                price: 1999,
                url: '/products/amazfit-x'
              }
            ]
          ]
        }
      ],
      categoryBand: [
        {
          title: '手环',
          products:
          [
            [
              {
                name: 'Amazfit 月霜手环',
                slogan: '天生优雅 时尚百搭',
                image: CDN_URL + '/images/band/moonbeam-2.jpg',
                price: 299,
                url: '/products/moonbeam'
              }
            ]
          ]
        }
      ],
      categoryHealth: [
        {
          title: '健康监测',
          products: [
            [
              {
                name: 'Amazfit 米动健康手表',
                slogan: 'ECG 非处方心电图 | AI 本地甄别心律不齐(含房颤)',
                image: CDN_URL + '/images/health/health-watch.jpg',
                price: 699,
                url: '/products/health-watch'
              }
            ],
            [
              {
                name: '米动健康手环 黄山1号版',
                slogan: 'ECG 非处方心电图',
                image: CDN_URL + '/images/health/health-band-chip.jpg',
                price: 399,
                url: '/products/health-band-chip'
              }
              // {
              //   name: '米动健康  VIP 卡',
              //   slogan: '银卡 | 金卡 | 白金卡',
              //   image: CDN_URL + '/images/health/vip-card.jpg',
              //   price: 299,
              //   priceStart: true,
              //   url: '/products/vip-card'
              // }
            ]
          ]
        }
      ],
      categoryEarphone: [
        {
          title: '耳机',
          products:
          [
            [
              {
                name: 'Amazfit PowerBuds Pro',
                slogan: '运动心率 | 颈椎保护 | 听力健康 <br/> 40dB 全场景主动降噪 | 三麦通话降噪',
                image: 'https://site-cdn.huami.com/files/amazfit/cn/m/single-powerbudspro.png',
                comingSoon: false,
                price: 999,
                url: '/products/powerbuds-pro'
              }
            ],
            [
              {
                name: 'Amazfit PowerBuds',
                slogan: '运动心率监测 | 磁吸运动耳挂 | 澎湃震撼音效<br/>IP55 防尘防水 | 24 小时长续航 | 双麦降噪通话',
                image: CDN_URL + '/images/earphone/powerbuds.jpg',
                comingSoon: false,
                price: 699,
                url: '/products/powerbuds'
              }
            ],
            [
              {
                name: 'Aamazfit ZenBuds',
                slogan: '耳塞遮噪设计 | 助眠音律 | 熟睡自动暂停播放<br/>睡眠监测 | 12 小时睡眠续航',
                image: CDN_URL + '/images/earphone/zenbuds.jpg',
                comingSoon: false,
                price: 999,
                url: '/products/zenbuds'
              }
            ]
          ]
        }
      ],
      categoryKidsWatch: [
        {
          title: '儿童手表',
          products:
          [
            [
              {
                name: 'Amazfit 快乐鸭儿童健康手表',
                slogan: '近视预防提醒 | 中小学体育达标 <br/> 多维健康监测 | 高清视频通话',
                image: 'https://site-cdn.huami.com/files/amazfit/cn/Hollywood/696348.png',
                comingSoon: false,
                price: 799,
                url: '/products/happya'
              }
            ]
          ]
        }
      ],
      categorySport: [
        {
          title: '鞋服',
          products: [
            [
              {
                name: 'Amazfit 羚羊轻户外跑鞋 2',
                slogan: '便捷系带旋钮 | 非牛顿流体材料中底 <br />GOODYEAR 橡胶大底 | FOOTBIRD 防滑缓震鞋垫',
                image: CDN_URL + '/images/sport/antelope2.jpg',
                price: 249,
                url: '/products/antelope2'
              }
            ],
            [
              {
                name: 'Amazfit 单向导湿短袖T恤 男款',
                slogan: '迅速排汗 | 轻盈透气 | 立体裁剪',
                image: CDN_URL + '/images/sport/single-t-shirt.jpg',
                price: 129,
                url: '/products/single-t-shirt'
              }
            ],
            [
              {
                name: 'Amazfit 四面弹皮肤风衣 男女款',
                slogan: '防晒防风 | 弹力面料 | 超轻舒适 | 便携收纳',
                image: CDN_URL + '/images/sport/coat.jpg',
                price: 199,
                url: '/products/coat'
              }
            ],
            [
              {
                name: 'Amazfit 轻量保暖抓绒外套',
                slogan: '告别静电 | 远离起球 | 防寒保暖',
                image: CDN_URL + '/images/sport/zhuarong.jpg',
                price: 99,
                url: '/products/zhuarong'
              },
              {
                name: 'Amazfit 全压胶防风透湿冲锋衣',
                slogan: '防风防水 | 全件压胶工艺',
                image: CDN_URL + '/images/sport/jackets.jpg',
                price: 399,
                url: '/products/jackets'
              }
            ],
            [
              {
                name: 'Amazfit 羚羊轻户外跑鞋',
                slogan: '减震高弹 | 防滑耐磨 | 舒适透气',
                image: CDN_URL + '/images/sport/antelope.jpg',
                price: 249,
                url: '/products/antelope'
              },
              {
                name: 'Amazfit 运动速干T恤',
                slogan: '速干排汗 | 轻盈透气 | 贴合身型',
                image: CDN_URL + '/images/sport/t-shirt.jpg',
                price: 129,
                url: '/products/t-shirt'
              }
            ]
          ]
        },
        {
          title: '配件',
          products: [
            [
              {
                name: 'Amazfit 表带 氟橡胶系列 轻盈透气款',
                slogan: '20mm | 22mm',
                image: 'https://site-cdn.huami.com/files/amazfit/common/cate/cate-mb-slim-breath.jpg',
                price: 129,
                url: '/products/slim-breath'
              }
            ],
            [
              {
                name: 'Amazfit 表带 氟橡胶系列 轻薄款',
                slogan: '20mm',
                image: CDN_URL + '/images/accessory/rubber-slim-wristbands.jpg',
                price: 129,
                url: '/products/rubber-slim-wristbands'
              }
            ],
            [
              {
                name: 'Amazfit 表带 运动系列-坚韧款',
                slogan: '20mm | 22mm',
                image: CDN_URL + '/images/accessory/sport-firm.jpg',
                price: 79,
                url: '/products/sport-firm'
              }
            ],
            [
              {
                name: 'Amazfit 表带 炫彩系列',
                slogan: '20mm | 22mm',
                image: CDN_URL + '/images/accessory/silicone-wristbands.jpg',
                price: 59,
                url: '/products/silicone-wristbands'
              }
            ],
            [
              {
                name: 'Amazfit 表带 氟橡胶系列 通用款',
                slogan: '20mm | 22mm',
                image: CDN_URL + '/images/accessory/rubber-wristbands.jpg',
                price: 129,
                url: '/products/rubber-wristbands'
              }
            ],
            // [
            //   {
            //     name: 'Amazfit 表带 运动系列-坚韧款',
            //     slogan: '20mm | 22mm',
            //     image: CDN_URL + '/images/accessory/sp-firm.jpg',
            //     price: 79,
            //     url: '/products/sport-firm'
            //   },
            //   {
            //     name: 'Amazfit 商务运动表带',
            //     slogan: '20mm | 22mm',
            //     image: CDN_URL + '/images/accessory/business-sport.jpg',
            //     price: 79,
            //     url: '/products/sport-firm'
            //   }
            // ],
            [
              {
                name: 'Amazfit 表带 真皮系列',
                slogan: '20mm | 22mm',
                image: CDN_URL + '/images/accessory/leather-wristbands.jpg',
                price: 129,
                url: '/products/leather-wristbands'
              },
              {
                name: 'Amazift 表带 运动系列-经典款',
                slogan: '简约舒适',
                image: CDN_URL + '/images/accessory/sport-wristbands.jpg',
                price: 79,
                url: '/products/sport-wristbands'
              }
            ],
            [
              {
                name: 'Amazfit 多孔运动表带',
                slogan: '20mm',
                image: CDN_URL + '/images/accessory/long-short-wristbands.jpg',
                price: 49,
                url: '/products/long-short-wristbands'
              },
              {
                name: '米动手表青春版 彩色腕带',
                slogan: '活力炫彩 | 个性张扬',
                image: CDN_URL + '/images/accessory/bip-colorful-wristbands.jpg',
                price: 49,
                url: '/products/bip-colorful-wristbands'
              }
            ]
          ]
        }
      ]
    }
  },
  created () {
    this.changeTab(this.index)
    this.getBanner()
    this.getCateBanner()
  },
  mounted () {
    this.headerHeight = document.getElementsByTagName('header')[0].offsetHeight
    window.addEventListener('scroll', this.onScroll)

    this.$nextTick(() => {
      let wrapper = document.querySelector('.nav')
      // eslint-disable-next-line no-new
      new BScroll(wrapper, {
        scrollX: true,
        eventPassthrough: 'vertical'
      })
    })
  },
  computed: {
    ...mapState([
      'index'
    ])
  },
  destroyed () {
    window.removeEventListener('scroll', this.onScroll)
  },
  methods: {
    ...mapActions([
      'editActiveTabFun'
    ]),
    changeTab (tab, section = null) {
      this.activeTab = tab
      let position = 0
      if (section) {
        setTimeout(() => {
          let sections = document.getElementsByClassName('category-item')

          if (sections && sections.length > 0) {
            position = position = sections[section].offsetTop - document.getElementsByTagName('header')[0].clientHeight - 50
          }
          window.scrollTo({
            top: position,
            behavior: 'smooth'
          })
        }, 200)
      } else {
        window.scrollTo({
          top: position,
          behavior: 'smooth'
        })
      }
      this.editActiveTabFun(tab)
    },
    goSection (section) {
      let position = 0
      let sections = document.getElementsByClassName('category-item')
      // position = sections[section].getBoundingClientRect().top - document.getElementsByTagName('header')[0].clientHeight - 20
      position = sections[section].offsetTop - document.getElementsByTagName('header')[0].clientHeight - 50
      window.scrollTo({
        top: position,
        behavior: 'smooth'
      })
    },
    onScroll () {
      const navContents = document.querySelectorAll('.category-item')
      // 所有锚点元素的 offsetTop
      const offsetTopArr = []
      navContents.forEach(item => {
        offsetTopArr.push(item.offsetTop - document.getElementsByTagName('header')[0].clientHeight - 50)
      })
      // 获取当前文档流的 scrollTop
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      // 定义当前点亮的导航下标
      let navIndex = 0
      for (let n = 0; n < offsetTopArr.length; n++) {
        // 如果 scrollTop 大于等于第n个元素的 offsetTop 则说明 n-1 的内容已经完全不可见
        // 那么此时导航索引就应该是n了
        if (scrollTop >= offsetTopArr[n]) {
          navIndex = n
        }
      }
      this.activeSubCate = navIndex
    },
    goLocation (url) {
      this.$router.push(url)
    },
    getBanner () {
      this.$http.defaults.headers.common['X-SITE'] = 'amazfit'
      this.$http.defaults.headers.common['X-LOCALE'] = 'cn'
      this.$http.get('https://api-site.huami.com/v1/banners?group=home-mb').then(function (res) {
        this.swiperData = res.data
      }.bind(this)).catch(function (err) {
        this.$toast.fail(err.response.data.message)
      }.bind(this))
    },
    getCateBanner () {
      this.$http.defaults.headers.common['X-SITE'] = 'amazfit'
      this.$http.defaults.headers.common['X-LOCALE'] = 'cn'
      const arr = ['watch-classify-mb', 'earphone-mb', 'treadmill-mb', 'clothes-classify-mb', 'health-classify-mb', 'kids-watch-mb']
      for (let i in arr) {
        this.$http.get('https://api-site.huami.com/v1/banners?group=' + arr[i]).then(function (res) {
          switch (arr[i]) {
            case 'watch-classify-mb': this.cateWatch = res.data[0]; break
            case 'earphone-mb': this.cateEarphone = res.data[0]; break
            case 'treadmill-mb': this.cateTreadmill = res.data[0]; break
            case 'clothes-classify-mb': this.cateSport = res.data[0]; break
            case 'health-classify-mb': this.cateHealth = res.data[0]; break
            case 'kids-watch-mb': this.kidsWatch = res.data[0]; break
          }
        }.bind(this)).catch(function (err) {
          this.$toast.fail(err.response.data.message)
        }.bind(this))
      }
    }
  }
}
</script>

<style lang="scss">
.hot-products {
  margin-top: 17.5px;
  display: flex;
  flex-wrap: wrap;

  .item {
    flex-basis: 25%;
    text-align: center;

    img {
      height: 60px;
    }

    .name {
      margin-top: 8px;
      font-size: 10px;
      line-height: 11px;
      color: #333333;
    }
  }
}

.home-page {
  background-color: #F3F3F3;

  .swiper {
    border-radius:5px;
    overflow: hidden;

    .van-swipe__indicator {
      background-color: #FFF;
    }

    .van-swipe__indicator--active {
      width: 4px;
      height: 4px;
      border-width: 2px;
      border-color: #FFF;
      background-color: transparent;
    }

    img {
      width: 100%;
      display: block;
    }
  }

  .category-nav {
    margin-top: 10px;

    .van-sticky--fixed {
      background-color: #FFF;

      .container {
        margin: 0 12.5px;

        a {
          &.current {
            font-weight: bold;
            border-bottom: 2px solid #FBC72E;
          }
        }
      }
    }

    .container {
      display: flex;
      justify-content: space-between;
    }

    a {
      padding: 10px 0 5px;
      text-align: center;
      font-size: 12px;
      line-height: 100%;
      color: #333;

      &:last-child {
        border: none;
      }
    }

    span {
      font-size: 10px;
      line-height: 100%;
    }
  }

  header {
    height: 35px;
    padding-top: 42.5px;
    position: relative;
    background-color: #FFF;
    border-bottom: 1px solid #e5e5e5;

    .logo {
      position: absolute;
      left: 125px;
      top: 12px;
      width: 125px;
    }

    .all-products {
      position: absolute;
      display: inline-block;
      bottom: 0;
      right: 0;
      padding: 5px 12px 0 25px;
      text-align: right;
      // overflow: hidden;
      margin-bottom: 6px;
      font-size: 13px;
      line-height: 100%;
      background: url(https://site-cdn.huami.com/files/amazfit/mobile/assets/product-nav-bg.png) no-repeat left center;
      background-size: auto 100%;
      background-color: #FFF;

      a {
        display: inline-block;
        color: #333;
        // border-radius: 5px;
        // box-shadow: -5px 0 25px 0 #BBB;
        padding-bottom: 5px;
      }
    }

    .nav {
      position: absolute;
      bottom: 0;
      // overflow-y: scroll;
      // -webkit-overflow-scrolling: touch;
      // overflow-x: auto;
      width: calc(100% - 100px);
      overflow: hidden;
      white-space: nowrap;

      .wrap {
        width: 450px;
        white-space: nowrap;
      }

       .item {
         display: inline-block;
         font-size: 13px;
         line-height: 100%;
         padding-bottom: 9px;
         margin: 0 10px 0 10px;
         padding-top: 3px;
         &.current {
           font-weight: bold;
           border-bottom: 2px solid #FBC72E;
         }
       }
    }
  }

  footer {
    padding: 37.5px;
    background-color: #FFF;
    color: #333;

    ul{
      li {
        a {
          display: inline-block;
          width: 100%;
          font-size:14px;
          line-height: 52px;
          border-bottom: 0.5px solid #333;
          color: #333333;
        }
      }
    }

    .about {
      margin-top: 40px;
      img {
        margin-left: 20px;
        width: 22.5px;
      }
    }

    .serivice {
      margin-top: 38.5px;
      font-size:15px;

      .tip {
        font-size: 12px;
      }
    }

    .phone {
      margin-top: 9.5px;
      font-size:30px;
      font-weight:bold;
    }
  }
}
</style>
